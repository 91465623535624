import { 
    defineComponent, 
    onMounted, 
    reactive, 
    getCurrentInstance, 
    ComponentInternalInstance,
 } from 'vue'
import { useRouter } from 'vue-router'
import { jobHighLights } from '@/api/index'  
import { SET_HIGHLIT } from '@/utils/constant'

import './index.less'

export default defineComponent({
    name: 'Highlights',
    setup () {
        const { appContext } = getCurrentInstance() as ComponentInternalInstance
        const proxy = appContext.config.globalProperties
        const router = useRouter()
        const state = reactive({ 
            data: [],
            num: 0, //职位亮点个数
         })

        onMounted(() => {
            _jobHighLights()
        })
        const _initLocal = (data: any) => {
            let res: any = localStorage.getItem(SET_HIGHLIT)
            if (res) {
                res = JSON.parse(res)
                data.forEach((item: any, index: number) => {
                    res.forEach((ite: any, idx: number) => {
                        if (item.id == ite.id) {
                            item['checked'] = true;
                            state.num++
                        }
                    })
                })
            }
            console.log(state.data, '啥。')
        }

        //  职位亮点
        const _jobHighLights = async() => {
            const res:any = await jobHighLights()
            res.data.forEach((item: any) => item['checked'] = false)
            state.data = res.data
            _initLocal(res.data)
        }

        const onSelect = (item: any) => {
            if (state.num <=5 && item['checked']) {
                item['checked'] = !item['checked']
                state.num --
                return
            }
            for (let i = 0; i < state.data.length; i++) {
                if (state.num > 4){
                    proxy.$toast("只能选择五个!");
                    return
                } 
            }
            
            !item['checked'] ? state.num ++ : state.num --

            item['checked'] = !item['checked']
            console.log(item, '选中。')
            console.log(state.data)
        }

        const onCancel = () => {
            state.data.forEach((item: any) => item['checked'] = false)
            router.push({path: '/publish2'})
        }

        const onSubmit = () => {
            console.log(state.data, 'sha.......')
            const data = state.data.filter((item: any) => item.checked == true)
            console.log(data, '处理的。。。。')
            //localStorage.removeItem(SET_HIGHLIT)
            localStorage.setItem(SET_HIGHLIT, JSON.stringify(data))
            router.back()
        }

        return () => (
            <div class="highlight-container">
                <div class="header-item">选择职位亮点（{ state.num  }/5）</div>
                <div class="highlight-page flex-box">
                    {
                        state.data.map((item: any) => {
                            return (
                                <div 
                                    class={`item-box ${ item.checked ? 'active-item' : '' }`} 
                                    key={item.id} 
                                    onClick={ () => onSelect(item) }
                                    >
                                        { item.name }
                                </div>
                            )
                        })
                    }
                </div>

                <div class="footer-box">
                    <button class="cancel-btn" onClick={onCancel}>取消</button>
                    <button class="save-btn" onClick={ onSubmit }>保存</button>
                </div>
            </div>
        )
    }
})